import React, { useEffect, useState } from "react";
import "./index.css";

import AdminListOrganization from "../Organization/List";
import AdminListTeam from "../Team/List";
import AdminHeader from "../AdminHeader/AdminHeader";
import AdminListUser from "../User/List";
import GroupMembers from "../../Administrator/Group/GroupMembers/GroupMembers";

const MENU_OPTIONS = [
  {
    index: 0,
    text: "Organization",
    value: "organization",
  },
  {
    index: 1,
    text: "Team",
    value: "team",
  },
  {
    index: 2,
    text: "User",
    value: "user",
  },
];

const MENU_INDEX = ["organization", "team"];

const CustomerSideBar = (props) => {
  const [component, setComponent] = useState("organization");
  const [orgComponent, setOrgComponent] = useState("vieworganization");
  const [teamComponent, setTeamComponent] = useState("viewteam");
  const [userComponent, setUserComponent] = useState("viewuser");
  const [selectedButton, setSelectedButton] = useState(0);

  const [organizationName, setOrganizationName] = useState("");
  const [organizationId, setOrganizationId] = useState(0);
  const [groupName, setGroupName] = useState("");
  const [groupId, setGroupId] = useState(0);

  const chooseOrganizationName = (organizationName) => {
    setOrganizationName(organizationName);
    setComponent("team");
    setSelectedButton(1);
  };

  const chooseOrganizationId = (organizationId) => {
    setOrganizationId(organizationId);
  };

  const chooseGroupName = (groupName) => {
    setGroupName(groupName);
    setComponent("user");
    setSelectedButton(2);
  };

  const chooseGroupId = (groupId) => {
    setGroupId(groupId);
  };

  const changeOrgName = (organizationName) => {
    setOrganizationName(organizationName);
  };

  const changeOrgId = (organizationId) => {
    setOrganizationId(organizationId);
  };

  const displayOrgComponent = () => {
    if (orgComponent === "vieworganization")
      return (
        <AdminListOrganization
          chooseOrganizationName={chooseOrganizationName}
          chooseOrganizationId={chooseOrganizationId}
        />
      );
  };

  const displayTeamComponent = () => {
    if (teamComponent === "viewteam") {
      return (
        <AdminListTeam
          orgName={organizationName}
          orgId={organizationId}
          changeOrgName={changeOrgName}
          changeOrgId={changeOrgId}
          chooseGroupName={chooseGroupName}
          chooseGroupId={chooseGroupId}
        />
      );
    }
  };

  const displayUserComponent = () => {
    if (userComponent === "viewuser") {
      return (
        // <AdminListUser
        //   orgName={organizationName}
        //   orgId={organizationId}
        //   grpName={groupName}
        //   grpId={groupId}
        // />
        <GroupMembers grpId={groupId} showBckOff={false} />
      );
    }
  };

  // const displayUserComponent = () => {
  //   if (userComponent === "viewuser") return <AdminListTeam />;
  // };

  const displayComponent = () => {
    if (component === "organization") {
      return <div>{displayOrgComponent()}</div>;
    } else if (component === "team") {
      return <div>{displayTeamComponent()}</div>;
    } else if (component === "user") {
      return <div>{displayUserComponent()}</div>;
    }
  };

  useEffect(() => {
    const propsOrgComponent = props.location?.state?.component;
    const propsOrgJournal = props.location?.state?.orgComponent;
    if (propsOrgComponent) {
      setComponent(propsOrgComponent);
      setSelectedButton(MENU_INDEX.indexOf(propsOrgComponent));
    }
    if (propsOrgJournal) setOrgComponent(propsOrgJournal);
  }, []);

  useEffect(() => {
    const propsTeamComponent = props.location?.state?.component;
    const propsTeamJournal = props.location?.state?.teamComponent;
    if (propsTeamComponent) {
      setComponent(propsTeamComponent);
      setSelectedButton(MENU_INDEX.indexOf(propsTeamComponent));
    }
    if (propsTeamJournal) setTeamComponent(propsTeamJournal);
  }, []);

  return (
    <div>
      <AdminHeader />
      <div
        className="min-h-content min-h-screen"
        style={{ backgroundColor: "#F9F9F9" }}
      >
        <div className="mx-4 pt-4">
          <h4>Customer</h4>
        </div>
        <div className="flex flex-row">
          <div className="p-4 m-4 bg-white h-full container md:w-1/3 lg:w-1/5">
            <div>
              {MENU_OPTIONS.map((option, index) => {
                return (
                  <div
                    key={index}
                    className="body-small-1 p-4 mb-4 cursor-pointer"
                    onClick={() => {
                      setComponent(option.value);
                      setSelectedButton(index);
                    }}
                    style={
                      index === selectedButton
                        ? {
                            backgroundColor: "#E9F3FD",
                            color: "#2288EE",
                            borderRadius: "10px",
                          }
                        : null
                    }
                  >
                    <div className="flex flex-row items-center gap-4">
                      {option.text}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="p-4 my-4 mr-4 bg-white h-full container overflow-auto">
            {component === "organization" && (
              <div className="mx-4">
                <h5>Organization</h5>
              </div>
            )}
            {displayComponent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSideBar;
