import React, { useEffect, useRef, useState } from "react";
import { API } from "../../../api";

// right now, email notification comprises all types of email. individual toggling needs to be done
// engagement_email is used as default

const Settings = () => {
  // const [reminderEmail, setReminderEmail] = useState();
  // const [engagementEmail, setEngagementEmail] = useState();
  // const [newsletterEmail, setNewsletterEmail] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState("");
  const checkRef = useRef();

  const updateNotification = async (e) => {
    const { status } = await API.post("user-data/update-notification", {
      reminder_email: e.target.checked,
      engagement_email: e.target.checked,
      newsletter_email: e.target.checked,
    });
    if (status === 200) {
      setEmail(e.target.checked);
      setMessage(
        `Email notifications has been turned ${e.target.checked ? "on" : "off"}`
      );
    }
  };

  useEffect(() => {
    async function getNotificationStatus() {
      const { data } = await API.get("user-data", "get-notification");
      // setReminderEmail(data.reminder_email);
      // setEngagementEmail(data.engagement_email);
      // setNewsletterEmail(data.newsletter_email);
      if (checkRef.current) checkRef.current.checked = data.engagement_email;
    }

    getNotificationStatus();
  }, []);

  return (
    <div className="m-4 p-4 my-4 bg-white mx-2 w-full rounded-lg shadow">
      <div className="p-4 mt-4" style={{ backgroundColor: "#E9F3FD" }}>
        <h6>Notifications</h6>
      </div>
      <div className="flex flex-row gap-4 mb-8 items-center mt-4">
        <div className="body-medium-1 ">Receive Email Notifications: </div>
        <div className="flex flex-col">
          <input
            type="checkbox"
            onClick={updateNotification}
            ref={checkRef}
            style={{ width: "1.5rem", height: "1.5rem" }}
          ></input>
        </div>
      </div>
      {message}
    </div>
  );
};

export default Settings;
