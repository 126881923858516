import React, { useState, useEffect } from "react";
import { API } from "../../../../api";
import useSWR from "swr";
import LinearButton from "../../../../components/Button";
import LinearTextBox from "../../../../components/TextBox";
import LinearTextArea from "../../../../components/TextArea";
import NewModal from "../../../../components/NewModal";
import Table from "../../../../components/Table";
import edit_svg from "../../../../assests/icons/ic/edit_1.svg";
import delete_svg from "../../../../assests/icons/ic/delete.svg";

export default function AdminListTeam({orgName, orgId, changeOrgName, changeOrgId, chooseGroupName, chooseGroupId,}) {
  
  const { data: organizationList } = useSWR("organization/all");

  const [groupList, setGroupList] = useState([]);
  const [organizationId, setOrganizationId] = useState(orgId);
  const [message, setMessage] = useState("");
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);
  const [showAddNewTeamModal, setShowAddNewTeamModal] = useState(false);
  const [showUpdateTeamModal, setShowUpdateTeamModal] = useState(false);
  const [showRemoveTeamModal, setShowRemoveTeamModal] = useState(false);

  const [orgIdForAddTeam, setOrgIdForAddTeam] = useState("");
  const [teamName, setTeamName] = useState("");
  const [teamDescription, setTeamDescription] = useState("");
  const [teamAdminEmail, setTeamAdminEmail] = useState("");

  const [currentGrp, setCurrentGrp] = useState({});
  const [updateName, setUpdateName] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [updateDescription, setUpdateDescription] = useState("");

  const [currentOrgName, setCurrentOrgName] = useState(orgName);
  const [currentOrgId, setCurrentOrgId] = useState(orgId);

  const [selectedOrgId, setSelectedOrgId] = useState(orgId);

  const [messageColor, setMessageColor] = useState("green");


  const getGroups = async () => {
    const { data } = await API.get(
      "group/all",
      `?organization_id=${organizationId}`
    );
    setGroupList(data);
  };

  useEffect(() => {
    getGroups();
  }, [organizationId]);

  const getEdit = (grp) => {
    const name = grp?.name;
    const id = grp?.id;
    const description = grp?.description;
    return (
      <div>
        <button
          onClick={(e) => {
            setMessage("");
            setShowUpdateTeamModal(true);
            setCurrentGrp({ name, id, description });
            setUpdateName(name);
            setUpdateId(id);
            setUpdateDescription(description);
          }}
        >
          <img src={edit_svg} alt="edit image" className="h-14" />
        </button>
      </div>
    );
  };

  const getDelete = () => {
    return (
      <div>
        <button
          onClick={(e) => {
            setMessage("");
            setShowRemoveTeamModal(true);
          }}
        >
          <img src={delete_svg} alt="delete image" className="h-7" />
        </button>
      </div>
    );
  };

  const connectUser = (group) => {
    const name = group?.name;
    const id = group?.id;
    return (
      <div>
        <button
          onClick={(e) => {
            chooseGroupName(name);
            chooseGroupId(id);
            changeOrgName(group.organization.name);
            changeOrgId(organizationId);
            // setOrgName(group.organization.name);
          }}
          style={{ color: "blue" }}
        >
          {name.toUpperCase()}
        </button>
      </div>
    );
  };

  useEffect(() => {
    const tableData = [];
    groupList &&
      groupList?.map((group, index) => {

        const grpName = connectUser(group);
        const grpOrgName = group.organization.name.toUpperCase();
        const edit = getEdit(group);
        const dele = getDelete();
        const element = [grpName, grpOrgName, edit, dele];
        tableData.push(element);
      });
    setTableHeader(["Team", "Organization", "Edit", "Delete"]);
    setTableBody(tableData);
  }, [groupList]);

  const addNewTeam = async () => {
    setMessageColor("green");
    if(teamName !== "" && teamDescription !== "" && teamAdminEmail !== ""){

      try {
        const { data, status } = await API.post("group/create-group", {
          name: teamName,
          description: teamDescription,
          organization_id: Number(selectedOrgId),
          group_admin_email: teamAdminEmail,
        });
        if (status === 200 || status === 201 || status === 202) {
          setMessage("Team added successfully");
        } else{
          setMessageColor("red");
          setMessage(data.error ? data.error.message : "Error Occured");
        }
      } catch (err) {
        setMessageColor("red");
        setMessage("An error occured. Please try again later.");
        //setMessage(err.message);
      }

    } else{
      setMessageColor("red");
      setMessage("Please fill the details");
    }
    getGroups();
  };

  const removeTeam = () => {
    setMessage("This option is currently not available now");
  };

  const updateTeam = async () => {
    setMessageColor("green");
    const { status } = await API.patch(`group`, {
      id : updateId,
      name: updateName,
      description: updateDescription,
    });
    if (status === 500 || status === 404 || status === 403) {
      setMessageColor("red");
      setMessage("An error occured");
    } else {
      setMessage("Team updated successfully");
    }
    getGroups();
  };

  const getOrgName =(organizationId) =>{
    for(let org of organizationList){
      if(org.id == organizationId){
        setCurrentOrgName(org.name);
        // setCurrentOrgId(org.id);
      }
    }
  }


  return (
    <div className="h-screen">
      <h5>Teams</h5>
      <div className="flex flex-row justify-center gap-4 items-center mb-4">
        <label className="body-small-1">Organization</label>
        <select
          onChange={(e) => {
            setOrganizationId(e.target.value);
            getOrgName(e.target.value);
            setMessage("");
            setCurrentOrgId(e.target.value);
            setSelectedOrgId(e.target.value);
          }}
          name="organization"
          className="input-box body-small-1"
          value={currentOrgId}
        >
            {organizationList?.map((org) => {
              return (
                <option value={org.id} key={org.id}>
                  {org.name}
                </option>
              );
            })}
        </select>
      </div>

      <div className={`flex flex-row justify-between items-center m-${4}`}>
        <button
          className="primary-button button-small text-white p-4"
          style={{ backgroundColor: "#003366", borderRadius: "10px" }}
          onClick={() => {
            setShowAddNewTeamModal(true);
          }}
        >
          Add New Team
        </button>
      </div>

      <Table header={tableHeader} body={tableBody} />

      {showAddNewTeamModal && (
        <NewModal
          heading={<div className="mx-16">Add new Team</div>}
          body={
              <div className="py-4 px-8 rounded-xl shadow-lg flex flex-col gap-4 bg-white">
                <div>
                  <LinearTextBox
                    name="name"
                    onChange={(e) => {
                      setTeamName(e.target.value);
                      setMessage("");
                    }}
                    label="Team name"
                    placeholder="Enter Team Name Here"
                  />
                </div>
                <div>
                  <LinearTextBox
                    name="description"
                    onChange={(e) => {
                      setTeamDescription(e.target.value);
                      setMessage("");
                    }}
                    label="Team description"
                    placeholder="Enter Team Description Here"
                  />
                </div>
                <div>
                  <LinearTextBox
                    name="admin_email"
                    onChange={(e) => {
                      setTeamAdminEmail(e.target.value);
                      setMessage("");
                    }}
                    label="Team admin email"
                    placeholder="Enter Team Admin Email Here"
                  />
                </div>
                <div className="flex flex-col w-full sm:w-2/5">
                  <label className="body-small-1">Organization</label>
                  <select
                    onChange={(e) => {
                      setOrgIdForAddTeam(e.target.value);
                      setMessage("");
                      setSelectedOrgId(e.target.value);
                    }}
                    name="organization"
                    className="input-box body-small-1"
                    value={selectedOrgId}
                  >
                    {organizationList && [" ", ...organizationList]?.map((organization, index) => {
                        return (
                          <option value={organization.id} key={index}>
                            {organization.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
          }
          footer={
            <div className="flex flex-col justify-center gap-2 items-center mx-16">
              <LinearButton text="Add Team" onClick={addNewTeam} />
              <h6 style={{color:`${messageColor}`}}>{message}</h6>
            </div>
          }
          onClose={() => {
            setShowAddNewTeamModal(false);
          }}
        />
      )}

      {showUpdateTeamModal && (
        <NewModal
          heading={<div className="mx-10">Update Team Details</div>}
          body={
            <div className="mx-16">
              <div>
                <LinearTextBox
                  onChange={(e) => {
                    setUpdateName(e.target.value);
                    setMessage("");
                  }}
                  label="Team Name"
                  defaultValue={currentGrp.name}
                />
                <LinearTextBox
                  onChange={(e) => {
                    setUpdateDescription(e.target.value);
                    setMessage("");
                  }}
                  label="Team Description"
                  defaultValue={currentGrp.description}
                />
              </div>
            </div>
          }
          footer={
            <div className="flex flex-col justify-center gap-4 items-center mx-16">
              <LinearButton text="Update" onClick={updateTeam} />
              <h6 style={{color:`${messageColor}`}}>{message}</h6>
            </div>
          }
          onClose={() =>{
            setShowUpdateTeamModal(false);
            
          }}
        />
      )}

      {showRemoveTeamModal && (
        <NewModal
          heading={<div className="mx-10">Are you sure?</div>}
          body={
            <div className="mx-16">
              <h6>Deleting team is currently not available!</h6>
            </div>
          }
          footer={
            <div className="flex flex-col justify-center gap-4 items-center mx-16">
              <h6 style={{color:`${messageColor}`}}>{message}</h6>
            </div>
          }
          onClose={() => setShowRemoveTeamModal(false)}
        />
      )}
    </div>
  );
}
