import React, { useEffect, useState } from "react";
import { API } from "../../../api";
import "./index.css";
import drill from "../../../assests/icons/drill.svg";
import deleteIcon from "../../../assests/icons/ic/delete-1.svg";
import useScreenSize from "../../../hooks/useScreenSize";
import Paginator from "../../../components/Paginator";

const FlashCardsJournalComponent = () => {
  const PAGE_SIZE = 5;

  const [flashCards, setFlashCards] = useState([]);
  const [isLoading, toggleLoader] = useState(false);
  const [lowerRange, setLowerRange] = useState(0);
  const [upperRange, setUpperRange] = useState(PAGE_SIZE);
  const { isMobile } = useScreenSize();
  const [refreshPage, setRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, status } = await API.get("user-data", "flashcards");
        if (status === 200) setFlashCards(data);
        toggleLoader(false);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [refreshPage]);

  const deleteFlashCard = async (id) => {
    try {
      const { status } = await API.post(`user-data/remove-flashcard/${id}`, {});
      if (status === 200) {
        setRefresh(!refreshPage);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="">
      <div className="flashCardParent m-4 bg-white">
        <div
          className="flex flex-col items-center"
          style={{
            overflowY: "scroll",
            scrollBehavior: "smooth",
            maxHeight: "70vh",
          }}
        >
          <div>
            <Paginator
              setUpperRange={setUpperRange}
              setLowerRange={setLowerRange}
              pageContents={PAGE_SIZE}
              totalLength={flashCards?.length}
            />
          </div>
          <div className="self-stretch">
            {flashCards
              ?.slice(lowerRange, upperRange)
              ?.map((flashcard, index) => {
                return (
                  <div key={index} className="mx-4 border-b-2 py-4">
                    <div className="flex flex-row justify-between items-center flex-wrap mb-4">
                      <div className="flex flex-row items-center justify-center gap-4 flex-wrap">
                        {isMobile ? (
                          <></>
                        ) : (
                          <img
                            alt="drill"
                            src={drill}
                            style={{ width: "1.5rem" }}
                          />
                        )}
                        <h6>{flashcard.flashcard_title}</h6>
                      </div>
                      <div className="ml-10 body-extra-small-1 flex gap-4 flex-row items-center ">
                        {new Date(flashcard.created_at).getDate()} /{" "}
                        {new Date(flashcard.created_at).getMonth() + 1} /{" "}
                        {new Date(flashcard.created_at).getFullYear()}
                        <img
                          alt="delete-flashcard"
                          src={deleteIcon}
                          onClick={() => {
                            deleteFlashCard(flashcard.id);
                          }}
                          style={{ width: "0.90rem", cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row gap-8 ml-10 body-medium-1 flex-wrap whitespace-pre-wrap	">
                      <div>{flashcard.flashcard_text}</div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="flashCardNone">
          {flashCards.length === 0 && <span>No saved flashCards found.</span>}
        </div>
      </div>
    </div>
  );
};

export default FlashCardsJournalComponent;
